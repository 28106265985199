import { chart } from 'highcharts';

{
    const { requestIdleCallback = setTimeout } = window;
    const dependencies = [
        '$log',
        'evChartProvider',
        configure
    ];
    const typeSeparator = ':';

    app.directive('evChart', dependencies);

    function configure($log, evChartProvider) {
        const restrict = 'A';
        const config = {
            restrict,
            controllerAs: '$evChartDirectiveCtrl',
            scope: {
                data: '<',
                chartOptions: '<'
            },
            link: (scope, { 0: element }, attributes) => link({
                scope,
                $log,
                element,
                attributes,
                evChartProvider
            })
        };

        return config;
    }

    function link({ $log, scope, element, attributes , evChartProvider}) {
        const { type: currentType = '', title = '', innerSize, referenceAngle } = attributes;
        const type = currentType
            .split(typeSeparator)
            .slice(0, 2)
            .join(typeSeparator);
        const { data: series  = [], chartOptions: currentChartOptions } = scope;
        const figure = element.querySelector('figure');
        const chartOptions = currentChartOptions || evChartProvider.getChartOptions({
            type,
            title,
            innerSize,
            series,
            referenceAngle
        });

        requestIdleCallback(() => chart(figure, chartOptions));
    }
}