import template from '../templates/ev-input-domain-validation.component.html?raw';

{
    app.component('evInputDomainValidation', {
        controllerAs: '$evInputDomainValidationCtrl',
        transclude: {
            label: '?evInputDomainValidationLabel',
            userFocusAction: '?evInputDomainValidationUserFocusAction',
            noMatchText: '?evInputDomainValidationNoMatch',
            errorMessage: '?evInputDomainValidationError',
            infoMessages: '?evInputDomainValidationInfo'
        },
        bindings: {
            userInputId: '@?',
            domainInputId: '@?',
            username: '@?',
            options: '<?',
            onUserValueChanged: '&',
            onDomainValueChanged: '&'
        },
        template
    });
}