{
    const { requestIdleCallback = setTimeout } = window;
    const dependencies = [
        '$log',
        configure
    ];

    app.directive('evSandbox', dependencies);

    function configure($log) {
        const config = {
            restrict: 'A',
            controllerAs: '$evSandboxCtrl',
            link: (scope, { 0: element }) => requestIdleCallback(() => link({ scope, $log, element }))
        };

        return config;
    }

    function link({ scope, $log, element }) {
        const evSandbox = element.closest('ev-sandbox');
        const iframe = element.querySelector('iframe');
        const { dataset: evSandboxData } = evSandbox;
        const { src: source } = iframe;

        evSandboxData.source = source;
    }
}