import template from '../templates/ev-icon-link.component.html?raw';

app.component('evIconLink', {
    controllerAs: '$evIconLink',
    bindings: {
        iconClass: '@',
        url: '@'
    },
    transclude: {
        label: '?linkLabel'
    },
    template
});