import template from '../templates/ev-seacrhable-dropdown.component.html?raw';

app.component('evSearchableDropdown', {
    controllerAs: '$evSearchableDropdown',
    bindings: {
        dropdownClass: '@',
        dropdownLabel: '@',
        dropdownSelected: '=',
        dropdownSearch: '&'
    },
    transclude: {
        list: '?dropdownOptions'
    },
    template
});

function main($scope) {
    const { $evSearchableDropdown: $ctrl } = $scope;
    $ctrl.inputSearch = '';
}