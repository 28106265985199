import template from '../templates/ev-stat-info.component.html?raw';
{
    app.component('evStatInfo', {
        controllerAs: '$evStatInfoCtrl',
        transclude: {
            label: '?statInfoLabel',
            primary: '?statInfoPrimary',
            secondary: '?statInfoSecondary'
        },
        template
    });
}