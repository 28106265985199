import { orderBy } from 'lodash';

{
    const { requestIdleCallback = setTimeout } = window;
    const dependencies = [
        '$log',
        configure
    ];
    const selector = {
        searchInput: '.ev-input-dropdown-search input'
    };
    const eventName = {
        change: 'keyup'
    };
    const optionOrdering = {
        fields: [ 'label' ]
    }

    app.directive('evInputDropdown', dependencies);

    function configure($log) {
        const config = {
            restrict: 'A',
            controllerAs: '$evInputDropdownDirectiveCtrl',
            scope: {
                'options': '=evInputDropdownOptions',
                'value': '=evInputDropdownValue',
                'search': '=evInputDropdownSearch',
                'handleValueChanged': '=evInputDropdownOnOptionClicked',
                'onValueChanged': '<evInputDropdownOnValueChanged'
            },
            link: (scope, { 0: element }) => requestIdleCallback(() => link({ scope, $log, element })),
            controller: ['$log', '$scope', controller]
        };

        return config;
    }

    function link({ scope, $log, element }) {
        const searchInput = element?.querySelector?.(selector.searchInput);

        searchInput?.addEventListener?.(eventName.change, event => handleSearchChange({ event, scope }));
    }

    function controller($log, $scope) {
        $scope.handleValueChanged = option => handleValueChanged({ option, $scope });

        setOptions({ $scope });
    }

    function setOptions({ $scope }) {
        const { fields = [] } = optionOrdering;
        const { options = [] } = $scope;
        const orderedOptions = orderBy(options, fields);
        const option = orderedOptions.find(({ checked }) => checked) || orderedOptions[0];

        $scope.options = orderedOptions;
        $scope.value = option?.label;

        if (option) {
            $scope?.onValueChanged?.({ option });
        }
    }

    function handleSearchChange({ event, scope }) {
        const { currentTarget: searchInput } = event;
        const { value: search } = searchInput;

        scope.search = search;

        scope?.$applyAsync?.();
    }

    function handleValueChanged({ option: currentOption = {}, $scope }) {
        const { label, value } = currentOption;
        const option = { label, value };

        $scope.value = label;

        $scope?.onValueChanged?.({ option });
    }
}