import template from '../templates/ev-create-campaign-modal.component.html?raw';

{
    const { app } = window;

    app.component('evCreateCampaignModal', {
        controllerAs: '$evCreateCampaignModalCtrl',
        transclude: {
            heading: '?evCreateCampaignModalHeading',
            campaignNameLabel: '?evCreateCampaignModalCampaignNameLabel',
            fromNameLabel: '?evCreateCampaignModalFromNameLabel',
            fromEmailLabel: '?evCreateCampaignModalFromEmailLabel',
            duplicateFromAndReplyLabel: '?evCreateCampaignModalDuplicateFromAndReplyLabel',
            replyEmailLabel: '?evCreateCampaignModalReplyEmailLabel',
            closeButtonLabel: '?evCreateCampaignModalCloseButtonLabel',
            saveButtonLabel: '?evCreateCampaignModalSaveButtonLabel'
        },
        bindings: {
            onReady: '&'
        },
        template
    });
}