import template from '../templates/ev-input-dropdown.component.html?raw';

{
    app.component('evInputDropdown', {
        controllerAs: '$evInputDropdownCtrl',
        transclude: {
            label: '?evInputDropdownLabel',
            noMatch: '?evInputDropdownNoMatch'
        },
        bindings: {
            inputId: '@?',
            iconText: '@?',
            options: '<?',
            readOnly: '@?',
            value: '@?',
            placeholderText: '@?',
            searchPlaceholderText: '@?',
            onValueChanged: '&'
        },
        template
    });
}