{
    const { app, fetch } = window;
    const dependencies = [
        '$log',
        '$q',
        EvCampaignService
    ];

    app.service('EvCampaignService', dependencies);

    function EvCampaignService($log, $q) {
        const methods = {
            getStandardCampaigns: ({ url = '' }) => getStandardCampaigns({ $log, $q, url })
        };

        return methods;
    }

    function getStandardCampaigns({ $log, $q, url = '' }) {
        const deferred = $q.defer();
        const response = fetch(url)
            .then(response => response.json())
            .then(data => handleGetStandardCampaignsSuccess({ $log, deferred, data }));

        return response;
    }

    function handleGetStandardCampaignsSuccess({ deferred, data = {} }) {
        const { campaigns: currentCampaigns = [], error } = data;
        const campaigns = currentCampaigns.map(campaign => ({ ...campaign, selected: false }));

        if (error) {
            return handleGetStandardCampaignsFailure({ deferred, error });
        }

        deferred.resolve({ campaigns });

        return deferred.promise
    }

    function handleGetStandardCampaignsFailure({ deferred, error }) {
        deferred.reject(error);

        return deferred.promise;
    }
}