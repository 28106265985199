{
    const $get = [
        '$log',
        getMethods
    ];

    app.provider('evInfoGraphicHorizontalStackedBarProvider', main);

    function main() {
        this.$get = $get;
    }

    function getMethods($log) {
        const methods = {
            getInputData
        };

        return methods;
    }

    function getInputData({ data: currentData = [], includeLegend = true, totalLabel = '' } = {}) {
        const total = currentData.reduce((currentTotal, { value }) => currentTotal + value, 0);
        const stacks = currentData.map(entry => ({
            ...entry,
            percentage: ((entry.value/total) * 100).toFixed(1) + '%'
        }));
        const legend = includeLegend && getLegend({ total, totalLabel, stacks });
        const inputData = { total, stacks, legend };

        return inputData;
    }

    function getLegend({ total, totalLabel, stacks }) {
        const legend = stacks.map(({ label, percentage: primary, value: secondary, colorClass }) => ({
            label,
            primary,
            secondary,
            colorClass
        }));

        legend.push({
            label: totalLabel,
            primary: total
        });

        return legend;
    }
}