import template from '../templates/ev-side-navigation.component.html?raw';

{
    app.component('evSideNavigation', {
        controllerAs: '$evSideNavigationCtrl',
        transclude: {
            link: '?evLink',
            title: '?evTitle',
        },
        bindings: {
            icon: '@',
            navigationItem: '<',
            navigationItems: '<',
            onNavigationItemClicked: '&'
        },
        template
    });
}