import template from '../templates/ev-personalisation-modal.component.html?raw';

{
    const { app } = window;

    app.component('evPersonalisationModal', {
        controllerAs: '$evPersonalisationModalCtrl',
        transclude: {
            title: '?evPersonalisationModalTitle',
            backLabel: '?evPersonalisationModalBackLabel',
            fallbackLabel: '?evPersonalisationModalFallbackLabel',
            insertLabel: '?evPersonalisationModalInsertLabel',
            createNewLabel: '?evPersonalisationModalCreateNewLabel',
            noResultsLabel: '?evPersonalisationModalNoResultsLabel',
        },
        bindings: {
            personalisationFields: '<?'
        },
        template
    });
}