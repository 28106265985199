{
    const { requestIdleCallback = setTimeout } = window;
    const dependencies = [
        '$log',
        'evInfoGraphicHorizontalStackedBarProvider',
        configure
    ];

    app.directive('evInfoGraphicHorizontalStackedBar', dependencies);

    function configure($log, evInfoGraphicHorizontalStackedBarProvider) {
        const restrict = 'A';
        const config = {
            restrict,
            controllerAs: '$evInfoGraphicHorizontalStackedBarCtrl',
            scope: {
                data: '<',
                inputData: '='
            },
            link: (scope, { 0: element }, attributes) => requestIdleCallback(() => link({
                scope,
                $log,
                element,
                attributes,
                evInfoGraphicHorizontalStackedBarProvider
            }))
        };

        return config;
    }

    function link({ $log, scope, element, attributes, evInfoGraphicHorizontalStackedBarProvider}) {
        const { data = [] } = scope;
        const { dataset } = element;
        const { legend = 'true', totalLabel = '' } = dataset;
        const includeLegend = !legend || legend === 'true' ? true : false;
        const inputData = evInfoGraphicHorizontalStackedBarProvider.getInputData({
            data,
            includeLegend,
            totalLabel
        });

        scope.inputData = inputData;

        scope.$applyAsync();

        requestIdleCallback(() => render({ element }));
    }

    function render({ element }) {
        const parentElement = element.closest('ev-info-graphic-horizontal-stacked-bar');

        if (parentElement) {
            parentElement.dataset.ready = '';
        }
    }
}