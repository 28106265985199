{
    const $get = [
        '$log',
        evDomainProvider
    ];

    app.provider('evDomainProvider', main);

    function main() {
        this.$get = $get;
    }

    function evDomainProvider($log)
    {
        const { validatedDomains  = [] } = window;
        const validatedDomainOptions = validatedDomains
            .map(({domain_id: value, domain_domain: label }) => ({ value, label }));
        const methods = {
            getValidatedDomains: () => validatedDomains,
            getValidatedDomainOptions: () => validatedDomainOptions,
        };

        return methods;
    }
}