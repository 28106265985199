import template from '../templates/ev-dropdown.component.html?raw';

app.component('evDropdown', {
    controllerAs: '$evDropdown',
    bindings: {
        dropdownClass: '@',
        dropdownLabel: '@',
        useIconLabel: '<'
    },
    transclude: {
        list: '?dropdownOptions'
    },
    template
});