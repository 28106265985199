import dayjs from 'dayjs';
import { set, cloneDeep as clone } from 'lodash';

{
    const { max } = Math;
    const UNIT = {
        day: 'day'
    };
    const dependencies = [
        '$log',
        'chartOptionsProvider',
        main
    ];

    app.factory('evChartProvider', dependencies);

    function main($log, chartOptionsProvider) {
        const methods = {
            getChartOptions: params => getChartOptions({params, chartOptionsProvider})
        };

        return methods;
    }

    function getPointStart({ type, data = [], date = new Date() }) {
        const unit = getUnit({ type });
        const value = data.length - 1;
        const currentDate = dayjs(date);
        const pointStart = unit && currentDate.subtract(value, unit);
        const pointStartUtc = pointStart && Date.UTC(pointStart.year(), pointStart.month(), pointStart.date());

        return pointStartUtc;
    }

    function getSeriesDataMax({ type, series = [] }) {
        const data = type.startsWith('line') && series
            .reduce((currentData, { data }) => currentData.concat(data), [])
            .map(entry => Array.isArray(entry) ? entry[1] : entry);
        const seriesDataMax = data && max(...data);

        return seriesDataMax;
    }

    function getUnit({ type }) {
        let unit = '';

        if (type.match('frequency-by-day')) {
            unit = UNIT.day;
        }

        return unit;
    }

    function getSeries({ type, innerSize, series: currentSeries = [], chartOptionsProvider }) {
        let series = currentSeries;

        if (type.startsWith('pie') && currentSeries.length) {
            series = getPieSeries({ type, innerSize, series: currentSeries, chartOptionsProvider });
        }

        return series;
    }

    function getPieSeries({ type, innerSize, series: currentSeries = [], chartOptionsProvider }) {
        const [seriesEntry] = currentSeries;
        const series = [];
        const { series: defaultSeries = [] } = chartOptionsProvider.get('pie:default');
        const { series: doughnutSeries = [] } = chartOptionsProvider.get('pie:doughnut');
        const [ defaultSeriesConfig = {} ] = defaultSeries;
        const [ doughnutSeriesConfig = {} ] = doughnutSeries;

        if (type.startsWith('pie')) {
            Object.assign(seriesEntry, {...defaultSeriesConfig });
        }

        if (type.startsWith('pie:doughnut')) {
            Object.assign(seriesEntry, {...doughnutSeriesConfig });
        }

        if (innerSize) {
            seriesEntry.innerSize = innerSize;
        }

        series.push(seriesEntry);

        return series;
    }

    function getStartAngle({ referenceAngle = '', series = [] }) {
        const [ { data: segments } ] = series;
        const total = segments.reduce((sum, [_, value]) => sum + value, 0);
        const [ segment ] = segments;
        const [ _, segmentValue ] = segment;
        const segmentPercentage = (segmentValue/total) * 100;
        const startAngle = referenceAngle ? Number(referenceAngle) - (segmentPercentage * 2) : 0;

        return startAngle;
    }

    function getChartOptions({ params, chartOptionsProvider }) {
        const {
            type,
            title = '',
            innerSize,
            series: currentSeries = [],
            referenceAngle
        } = params;
        const series = getSeries({
            type,
            innerSize,
            series: currentSeries,
            chartOptionsProvider
        });
        const pointStart = series?.[0]?.data && getPointStart({
            type,
            data: series[0].data
        });
        const yAxisMax = series[0]?.data && getSeriesDataMax({
            type,
            series
        });
        const startAngle = series[0]?.data && referenceAngle && getStartAngle({
            referenceAngle,
            series
        });
        const defaultChartOptions = chartOptionsProvider.get(type || 'default');
        const chartOptions = clone({
            ...defaultChartOptions,
            series
        });

        if (title) {
            set(chartOptions, 'title.text', title);
        }

        if (pointStart) {
            set(chartOptions, 'plotOptions.series.pointStart', pointStart);
        }

        if (yAxisMax) {
            set(chartOptions, 'yAxis.max', yAxisMax);
        };

        if (startAngle) {
            set(chartOptions, 'plotOptions.pie.startAngle', startAngle);
        }

        return chartOptions;
    }
}