const { requestIdleCallback = setTimeout } = window;
const baseChartOptions = {
    chart: {
        events: {
            render: (event) => handleRender({ ...event })
        }
    },
    credits: {
        enabled: false
    },
    plotOptions: {
        series: {
            animation: false
        }
    },
    series: []
};
const charts = new Set();

export default baseChartOptions;

function handleRender({ target: chart }) {
    if (!charts.has(chart)) {
        charts.add(chart);

        requestIdleCallback(() => displayChart({ chart }));
    }
}

function displayChart({ chart }) {
    const chartContainer = chart.container.closest('ev-chart');

    setTimeout(() => chart.reflow(), 100);

    setTimeout(() => requestIdleCallback(() => chartContainer.dataset.ready = 'true'), 500);
}