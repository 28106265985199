import template from '../templates/ev-composition-header.component.html?raw';

{
    const { app } = window;

    app.component('evCompositionHeader', {
        controllerAs: '$evCompositionHeaderCtrl',
        transclude: {
            title: '?evCompositionHeaderTitle',
        },
        template
    });
}