(function () {
    const { app } = window;
    const dependencies = [
        '$log',
        '$timeout',
        ApiMessageReportProvider
    ];
    const regex = {
        nonDigits: /[\D]+/g,
        no: /^no$/i,
        yes: /^yes$/i,
        sent: /^sent$/i,
        rateLimit: /^rate-limit$/i,
        stagger: /^stagger$/i
    }
    const config = {
        dateFormat: 'DD-MM-YYYY',
        reverseDateFormat: 'YYYY-MM-DD',
        dateTimeFormat: 'DD MMM YYYY - hh:mm',
        url: {
            contactProfile: '/contacts/profile'
        },
        messageStatus: {
            icon: {
                sent: 'seve-icon-chat-2 text-success',
                staggered: 'seve-icon-clock-2 text-warning'
            }
        },
        tab: {
            icon: {
                Composition: 'seve-icon-brush-1',
                Lists: 'seve-icon-list-1',
                'Sending Options': 'seve-icon-plane-paper-1',
                Tracking: 'seve-icon-antenna-1'
            }
        },
        default: {
            na: 'N/A'
        },
        icon: {
            listFilter: 'seve-icon-filter text-primary'
        },
        tracking: {
            fields: [
                'message_include_analytics',
                'message_include_custom_analytics',
                'message_track_reads',
                'message_track_links',
                'message_track_attachments'
            ],
            icon: {
                yes: 'seve-icon-check-mark-circle-two text-success',
                no: 'seve-icon-close-delete text-error'
            }
        }
    };

    app.provider('ApiMessageReportProvider', main);

    function main() {
        this.$get = dependencies;
    }

    function ApiMessageReportProvider($log, $timeout) {
        const methods = {
            debounce,
            getMessageOverviewStats,
            getActiveContactIds,
            getSendLogContacts,
            getNavigationItem,
            getTranslations,
            getSendLogPaginationData,
            getTrackingLinksPaginationData,
            getSendLogItem,
            getTrackingLinksLogItem,
            getMessageListInfo,
            getCreditsUsed,
            getMessageListFilterNames,
            getMessageUsers,
            mergeResponses,
            getMessageStatusInfo,
            getTabLabels,
            getMessageLists,
            getMessageTracking,
            getTrackingLinksSummary
        };

        return methods;

        function getMessageOverviewStats({ messageStats = {}, translations = {} }) {
            const {
                sent,
                delivered,
                total_queued: totalQueued
            } = messageStats;
            const sentPercentage = Number(((sent/totalQueued) * 100).toFixed(2));
            const deliveredPercentage = Number(((delivered/totalQueued) * 100).toFixed(2));
            const failed = totalQueued - delivered;
            const failedPercentage = 100 - deliveredPercentage;
            const overviewStats = [
                [translations['Total Sent'] || 'Total Sent', sent, sentPercentage, '#3498db'],
                [translations['Delivered'] || 'Delivered', delivered, deliveredPercentage, '#1abc9c'],
                [translations['Failed'] || 'Failed', failed, failedPercentage, '#e74c3c']
            ].map(([label, value, percentage, color ]) => ({
                label,
                value,
                percentage: `${isNaN(percentage) || !isFinite(percentage) ? 0 : percentage}%`,
                percentageValue: 0,
                color
            }));

            return overviewStats;
        }

        function getActiveContactIds({ sendLog = {} }) {
            const { contacts = [] } = sendLog;
            const contactIds = new Set(
                contacts.map(({ contact_id: contactId }) => Number(contactId))
            );

            return Array.from(contactIds);
        }

        function getSendLogContacts({ sendLog, info }) {
            const { contacts = [] } = sendLog;
            const sendLogContacts = [];

            for (const contact of contacts) {
                sendLogContacts.push({
                    ...contact,
                    info: info.find(({ contact_id }) => contact_id === Number(contact.contact_id)) || {}
                })
            }

            return sendLogContacts;
        }

        function getNavigationItem({ navigationItem, templateUrl: templateUrls }) {
            const { value = '', label = '' } = navigationItem;
            const templateUrl = templateUrls[value];

            return { value, label, templateUrl };
        }

        function getTranslations({ translations: currentTranslations = [] }) {
            const translations = {};

            for (const { value = '', label = '' } of currentTranslations) {
                translations[value] = label;
            }

            return translations;
        }

        function getSendLogPaginationData(params) {
            const {
                total,
                from,
                to,
                per_page: pageSize,
                current_page: current,
                last_page: last,
                data = []
            } = params;
            const sendLog = data.map(getSendLogItem);
            const pagination = { total, from, to, pageSize, current, last };

            return { pagination, sendLog };
        }

        function getTrackingLinksPaginationData(params) {
            const {
                total,
                from,
                to,
                per_page: pageSize,
                current_page: current,
                last_page: last,
                data = []
            } = params;
            const page = data.map(getTrackingLinksLogItem);
            const pagination = { total, from, to, pageSize, current, last };

            return { pagination, page };
        }

        function getSendLogItem(log) {
            const {
                contact_id: id,
                contact_name: name = '',
                contact_lastname: surname = '',
                cm_send_date = ''
            } = log;
            const fullName = [name, surname].join(' ').trim();
            const sendDate = cm_send_date ? moment.unix(cm_send_date).format(config.dateFormat)
                : cm_send_date;
            const contactProfileUrl = [config.url.contactProfile, id].join('/');
            const sendLogItem = {
                ...log,
                fullName,
                sendDate,
                contactProfileUrl
            };

            return sendLogItem;
        }

        function getTrackingLinksLogItem(log) {
            const {
                mlink_link_lookup_url: url,
                total_link_clicks: linkClicks,
                total_unique_clicks: uniqueClicks,
                total_contacts_clicks: contactClicks,
                mlink_day,
                mlink_hour: hour
            } = log;
            const day = moment(mlink_day, config.reverseDateFormat).format(config.dateFormat);
            const linksTrackingItem = {
                url,
                day,
                linkClicks: Number(linkClicks),
                uniqueClicks: Number(uniqueClicks),
                contactClicks: Number(contactClicks),
                hour: Number(hour)
            };

            return linksTrackingItem;
        }

        function getMessageListInfo({ message }) {
            const {
                message_list_ids: messageListIds = '',
                message_unfiltered_list_ids: unfilteredListIds = '',
                message_total_sent = '0',
                message_total_failed = '0'
            } = message;
            const listIds = Array.from(new Set([
                ...getListIds(messageListIds),
                ...getListIds(unfilteredListIds)
            ]));
            const totalSent = Number(message_total_sent);
            const totalFailed = Number(message_total_failed);
            const totalContacts = totalSent + totalFailed;
            const messageListInfo = {
                listIds,
                total: {
                    lists: listIds.length,
                    contacts: totalContacts
                }
            };

            return messageListInfo;
        }

        function getListIds(listIdsString = '') {
            const listIds = listIdsString
                .split(regex.nonDigits)
                .map(listId => listId.trim())
                .filter(listId => listId);

            return listIds;
        }

        function getCreditsUsed( { message }) {
            const { message_total_actual_sent = '0',  message_total_actual_failed = '0' } = message;
            const totalSent = Number(message_total_actual_sent);
            const totalFailed = Number(message_total_actual_failed);
            const creditsUsed = totalSent + totalFailed;

            return creditsUsed;
        }

        function getMessageListFilterNames({ messageListFilters = [] }) {
            const messageListFilterNames = messageListFilters.map(({ filter_name: filterName }) => filterName);

            return messageListFilterNames;
        }

        function getMessageUsers({ message = {}, messageUser: user = {} }) {
            const { message_create_date = '' } = message;
            const { user_name: createdBy } = user;
            const dateCreated = message_create_date
                ? moment.unix(message_create_date).format(config.dateTimeFormat) : message_create_date;
            const messageUser = {
                createdBy,
                dateCreated
            };
            const messageUsers = [ messageUser ];

            return messageUsers;
        }

        function mergeResponses(responses = []) {
            const mergedResponse = {};

            for (const response of responses) {
                Object.assign(mergedResponse, response);
            }

            return mergedResponse;
        }

        function getMessageStatusInfo({ message = {}, translations = {} }) {
            const { message_status: status = 'sent', message_is_scheduled = 'no' } = message;
            const icon = message_is_scheduled.match(regex.no) ? config.messageStatus.icon.sent
                : config.messageStatus.icon.staggered;
            const stagger = getStaggerInfo({ message, translations });
            const messageStatusInfo = {
                icon,
                ...stagger
            };

            return messageStatusInfo;
        }

        function getStaggerInfo({ message = {}, translations = {} }) {
            const {
                message_stagger_type: staggerType = 'none',
                message_status: status,
                message_stagger_window_time: staggerWindowTime = '',
                message_stagger_window_count: staggerWindowCount
            } = message;
            const [ staggerTimeCount = '0', staggerTimeUnit = 'minute' ] = staggerWindowTime
                .split(' ')
                .filter(entry => entry.trim());
            const staggerInfo = {
                none: {
                    title: translations['Not Staggered or Rate-Limited'],
                    description: config.default.na
                },
                stagger: {
                    title: translations['Staggered'],
                    description: [
                        status.match(regex.sent) ? translations['Paused every'] : translations['Pause every'],
                        staggerWindowCount,
                        translations['messages']
                    ].join(' ')
                },
                'rate-limit': {
                    title: translations['Rate limited'],
                    description: [
                        status.match(regex.sent) ? translations['Sent'] : translations['Sending'],
                        staggerWindowCount,
                        translations['messages every'],
                        staggerTimeCount,
                        `${staggerTimeUnit}s`.toLowerCase()
                    ].join(' ')
                }
            };
            const stagger = staggerInfo[staggerType] || staggerInfo.none;

            return stagger;
        }

        function getTabLabels({ tabs = [], templateUrl: url = {} }) {
            const tabLabels = [];

            let icon = '';
            let templateUrl = '';

            for (const { label: name, value: param } of tabs) {
                icon = config.tab.icon[name];
                templateUrl = url[name];

                tabLabels.push({ name, param, icon, templateUrl });
            }

            return tabLabels;
        }

        function getMessageLists({ message = {}, lists = [], messageImport = {}, messageListInfo = {} }) {
            const { message_filter_id = '0' } = message;
            const filterId = Number(message_filter_id);
            const { import_list_ids = {}, import_customer_filename: fileName = '' } = messageImport || {};
            const { listIds = [] } = messageListInfo;
            const currentLists = lists.filter(({ list_id: listId }) => listIds.includes(listId));
            const importListIds = Object.keys(import_list_ids);
            const messageLists = [];

            let messageList;
            let importFileName;
            let listFilter;

            for (const { list_id: listId = '', list_name: listName = '', list_email_active_contacts: email = 0, list_sms_active_contacts, sms = 0} of currentLists) {
                listFilter = {
                    icon: filterId ? config.icon.listFilter : undefined,
                    label: filterId ? undefined: config.default.na
                };

                importFileName = importListIds.includes(listId) && fileName || config.default.na;

                messageList = {
                    listName,
                    importFileName,
                    listFilter,
                    contacts: {
                        email,
                        sms
                    }
                }

                messageLists.push(messageList);
            }

            return messageLists;
        }

        function getMessageTracking({ message = {} }) {
            const { fields = [], icon = {} } = config.tracking;
            const messageTracking = [];

            let fieldValue;

            for (const field of fields) {
                fieldValue = message[field]
                messageTracking.push({
                    field,
                    icon: icon[fieldValue],
                    label: fieldValue && icon[fieldValue] ? undefined : config.default.na
                });
            }

            return messageTracking;
        }

        function getTrackingLinksSummary({ translations = {}, messageTrackingLinksSummary = {} }) {
            const {
                click_to_open_rate: clickToOpenRate = 0,
                total_link_clicks: totalClicks = 0,
                total_unique_clicks: uniqueClicks = 0,
                total_contacts_clicks: contactClicks = 0,
                engagement = 0
            } = messageTrackingLinksSummary || {};
            const trackingLinksSummary = [
                {
                    label: 'CTOR',
                    value: Number(clickToOpenRate) + '%'
                },
                {
                    label: translations['Total Clicks'],
                    value: Number(totalClicks)
                },
                {
                    label: translations['Unique Clicks'],
                    value: Number(uniqueClicks)
                },
                {
                    label: translations['Contact Clicks'],
                    value: Number(contactClicks)
                },
                {
                    label: translations['Engagement'],
                    value: engagement
                }
            ];

            return trackingLinksSummary;
        }

        function debounce(callback, delay) {
            let interval;
            const debounceCallback = () => {
                $timeout.cancel(interval);

                interval = $timeout(callback, delay)
            };

            return debounceCallback;
        }
    }
})();
