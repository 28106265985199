{
    const { app, fetch, URLSearchParams } = window;
    const dependencies = [
        '$http',
        '$q',
        '$log',
        EvPersonalisationService
    ];

    app.service('EvPersonalisationService', dependencies);

    function EvPersonalisationService($http, $q, $log) {
        const methods = {
            getPersonalisationFields: ({ url = '' }) => getPersonalisationFields({ $http, $q, $log, url }),
            getPersonalisationFieldNames: ({ url = '' }) => getPersonalisationFieldNames({ $http, $q, $log, url }),
            getPersonalisationFieldsFallback: ({ url = '', fields = [] }) => getPersonalisationFieldsFallback({ $http, $q, $log, url, fields }),
            getPersonalisationFieldsRetry: ({ url = '', fields = [] }) => getPersonalisationFieldsRetry({ $http, $q, $log, url, fields })
        };

        return methods;
    }

    function getPersonalisationFields({ $http, $q, $log, url = '' }) {
        const response = fetch(url)
            .then(response => response.json())
            .then(data => handleGetPersonalisationFieldsSuccess({ $log, $q, data }));

        return response;
    }

    function getPersonalisationFieldNames({ $http, $q, $log, url = '' }) {
        const response = fetch(url)
            .then(response => response.json())
            .then(data => handleGetPersonalisationFieldNamesSuccess({ $log, $q, data }));

        return response;
    }

    async function getPersonalisationFieldsFallback({ $http, $q, $log, url = '', fields = [] }) {
        const deferred = $q.defer();
        const errorFields = new Set();
        const response = await Promise.all(
            fields.map((field = '') => getPersonalisationField({ $http, url, field })
                .then(data => handleGetPersonalisationFieldSuccess({ $log, $q, data, field }))
                .catch(() => errorFields.add(field)))
        ).then(responses => handleGetPersonalisationFieldsFallbackSuccess({ responses, errorFields }));

        deferred.resolve(response);

        return deferred.promise;
    }

    async function getPersonalisationFieldsRetry({ $http, $q, $log, url = '', fields = [] }) {
        const deferred = $q.defer();
        const errorFields = new Set();
        const responses = [];

        let responseResult = Promise.resolve();

        for (const field of fields) {
            responseResult = responseResult
                .then(() => getPersonalisationField({ $http, url, field }))
                .then(data => handleGetPersonalisationFieldSuccess({ $log, $q, data, field }))
                .then(response => responses.push(response))
                .catch(() => errorFields.add(field))
        }

        responseResult = await responseResult.then(() => handleGetPersonalisationFieldsFallbackSuccess({ responses, errorFields }));

        deferred.resolve(responseResult);

        return deferred.promise;
    }

    function getPersonalisationField({ $http, url: currentUrl = '', field: fieldName = '' }) {
        const params = { fieldName };
        const url = [
            currentUrl,
            new URLSearchParams(params)
        ].join('?');
        const response = fetch(url)
            .then(response => response.json());

        return response;
    }

    function handleGetPersonalisationFieldsSuccess({ $log, $q, data = {} }) {
        const deferred = $q.defer();
        const { personalisation_fields: personalisationFields = {}, error } = data;

        if (error) {
            return handleRequestFailure({ $log, $q, error });
        }

        deferred.resolve({ personalisationFields });

        return deferred.promise;
    }

    function handleGetPersonalisationFieldsFallbackSuccess({ responses = [], errorFields = new Set() }) {
        const response = {
            personalisationFields: {},
            personalisationErrorFields: Array.from(errorFields)
        };

        for (const currentResponse of responses) {
            for (const [field, value] of Object.entries(currentResponse || {})) {
                response.personalisationFields[field] = value;
            }
        }

        return response;
    }

    function handleGetPersonalisationFieldNamesSuccess({ $log, $q, data = {} }) {
        const deferred = $q.defer();
        const { personalisationFieldNames = {}, error } = data;

        if (error) {
            return handleRequestFailure({ $log, $q, error });
        }

        deferred.resolve({ personalisationFieldNames });

        return deferred.promise;
    }

    function handleGetPersonalisationFieldSuccess({ $log, $q, data = {} }) {
        const { personalisationField = {}, error } = data;

        if (error) {
            return handleRequestFailure({ $log, $q, error });
        }

        return personalisationField;
    }

    function handleRequestFailure({ $log, $q, error }) {
        const deferred = $q.defer();

        deferred.reject({ error });

        return deferred.promise;
    }
}