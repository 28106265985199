import template from '../templates/ev-sandbox.component.html?raw';

{
    app.component('evSandbox', {
        controllerAs: '$evSandboxCtrl',
        bindings: {
            url: '@'
        },
        template
    });
}