import template from '../templates/ev-tag.component.html?raw'

{
    app.component('evTag', {
        controllerAs: '$evTagCtrl',
        transclude: true,
        bindings: {
            allowIconClick: '@?allowIconClick',
            tooltip: '@?',
            tag: '@?tag',
            onIconClick: '&'
        },
        template
    })
}