{
    const dependencies = [
        '$http',
        '$q',
        '$log',
        EvInputDomainValidationService
    ];
    const config = {
        url: {
            getValidDomains: '/domains/valid-domains'
        }
    }

    app.service('EvInputDomainValidationService', dependencies);

    function EvInputDomainValidationService($http, $q, $log) {
        const methods = {
            getValidDomains: () => getValidDomains({ $http, $q, $log })
        }

        return methods;
    }

    function getValidDomains({ $http, $q, $log }) {
        const { getValidDomains: url } = config.url;
        const response = $http
            .get(url)
            .then(response => handleGetValidDomainsSuccess({ $log, $q, response }))
            .catch(response => handleGetValidDomainsFailure({ $log, $q, ...response }));

        return response;
    }

    function handleGetValidDomainsSuccess({ $log, $q, response }) {
        const { data = {} } = response;
        const { validDomains = [], error } = data;
        const validDomainOptions = validDomains
            .map(({domain_id: value, domain_domain: label }, index) => ({ value, label, checked: !index }));

        if (error) {
            return handleGetValidDomainsFailure({ $log, $q, error })
        }

        return { validDomains, validDomainOptions };
    }

    function handleGetValidDomainsFailure({ $log, $q, error }) {
        const deferred = $q.defer();

        deferred.reject({ error });

        return deferred.promise;
    }
}