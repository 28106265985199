import { dateFormat } from 'highcharts';
import { set, cloneDeep as clone } from 'lodash';
import lineChartOptions from '../config/line-chart-options.config';
import pieChartOptions from '../config/pie-chart-options.config';
import doughnutPieChartOptions from '../config/doughnut-pie-chart-options.config';

{
    const dependencies = [
        main
    ];
    const chartOptions = new Map();
    const lineChartOptionsDaily = getLineFrequencyByDayChartOptions();
    const lineChartOptionsHourly = getLineFrequencyByHourlyChartOptions();
    const defaultPieChartOptions = getDefaultPieChartOptions();
    const currentDoughnutPieChartOptions = getDoughnutPieChartOptions();

    chartOptions.set('default', clone(lineChartOptions));
    chartOptions.set('line:frequency-by-day', lineChartOptionsDaily);
    chartOptions.set('line:frequency-by-hour', lineChartOptionsHourly);

    chartOptions.set('pie:default', defaultPieChartOptions);
    chartOptions.set('pie:doughnut', currentDoughnutPieChartOptions);

    app.provider('chartOptionsProvider', dependencies);

    function main() {
        this.$get = () => chartOptions;
    }

    function getLineFrequencyByDayChartOptions() {
        const date = new Date();
        const defaultChartOptions = clone(lineChartOptions);
        const chartOptions = {
            ...defaultChartOptions,
            xAxis: {
                ...defaultChartOptions.xAxis,
                type: 'datetime',
                labels: {
                    ...defaultChartOptions.xAxis.labels,
                    formatter: function () {
                        return dateFormat('%e %b %Y', this.value)
                    }
                }
            }
        };

        set(chartOptions, 'plotOptions.series.pointStart', Date.UTC( date.getFullYear(), date.getMonth(), date.getDate()));
        set(chartOptions, 'plotOptions.series.pointIntervalUnit', 'day');

        return chartOptions;
    }

    function getLineFrequencyByHourlyChartOptions() {
        const defaultChartOptions = clone(lineChartOptions);
        const chartOptions = {
            ...defaultChartOptions,
            xAxis: {
                ...defaultChartOptions.xAxis,
                type: 'datetime',
                labels: {
                    ...defaultChartOptions.xAxis.labels,
                    formatter: function () {
                        return dateFormat('%H:%M', this.value)
                    }
                }
            }
        };

        return chartOptions;
    }

    function getDefaultPieChartOptions() {
        const defaultChartOptions = clone(pieChartOptions);
        const chartOptions = {
            ...defaultChartOptions
        };

        return chartOptions;
    }

    function getDoughnutPieChartOptions() {
        const defaultChartOptions = clone(doughnutPieChartOptions);
        const chartOptions = {
            ...defaultChartOptions
        };

        return chartOptions;
    }
}