import './styles/index.less';
import './providers';
import './services';
import './directives';
import './components';
import './filters';
import './controllers';
import './utilities';
import {
    stylesheetHref,
    domain as configDomain,
    host as configHost,
    className,
    eventName
} from './config/lib.config';

addEventListener('load', handleLoad);

function handleLoad() {
    const { location, content, document, $ } = window;
    const { documentElement } = document;
    const { content: contentClass, contentRemove } = className;
    const { modalShown } = eventName;
    const { hostname, port } = location;
    const { local: localHost } = configHost;
    const href = getStylesheetUrl({ hostname });
    const localEnvironment = Boolean(localHost.includes(hostname) && port);

    if (!localEnvironment) {
        loadStylesheet({ href });
    }

    content?.classList?.remove?.(contentRemove);
    content?.classList?.add?.(contentClass);

    $(documentElement).on(modalShown, handleModalShown);

    // TODO - This was added due to the styles not loading for Argentinian Client
    if (content?.dataset) {
        content.dataset.ready = '';
    }
}

function getStylesheetUrl({ hostname }) {
    const { dev: devHost = [] } = configHost;
    const { dev, prod } = configDomain;
    const domain = devHost.includes(hostname) ? dev : prod;
    const href = [domain, stylesheetHref].join('/');

    return href;
}

function loadStylesheet({ href }) {
    const { head } = document;
    const link = document.createElement('link');

    link.rel = 'stylesheet';
    link.href= href;

    head.appendChild(link);

    link.addEventListener('load', handleStylesheetLoaded);
}

function handleStylesheetLoaded() {
    const { content } = window;

    if (content && content.dataset) {
        content.dataset.ready = '';
    }
}

function handleModalShown({ target: modal }) {
    const { document } = window;
    const { body } = document;
    const parent = modal.parentElement;

    if (parent !== body) {
        parent.removeChild(modal);
        body.appendChild(modal);
    }
}
