{
    const { requestIdleCallback = setTimeout } = window;
    const dependencies = [
        '$log',
        'EvRegexUtility',
        configure
    ];
    const selector = {
        domainValidation: 'ev-input-domain-validation',
        domainUserInput: '.ev-input-domain-validation-user-input input',
        formGroup: '.form-group'
    };
    const className = {
        hasError: 'has-error'
    };
    const eventName = {
        focusIn: 'focus',
        focusOut: 'blur',
        keyup: 'keyup'
    };

    app.directive('evInputDomainValidation', dependencies);

    function configure($log, EvRegexUtility) {
        const config = {
            restrict: 'A',
            controllerAs: '$evInputDropdownDirectiveCtrl',
            scope: {
                onUserValueChanged: '&evInputDomainValidationOnUserValueChanged'
            },
            link: (scope, { 0: element }, attrs) => requestIdleCallback(() => link({ scope, $log, element, attrs, EvRegexUtility }))
        };

        return config;
    }

    function link({ scope, $log, element, EvRegexUtility }) {
        const { focusIn, focusOut, keyup } = eventName;
        const domainValidationElement = element?.closest(selector.domainValidation);
        const domainUserInput = element?.querySelector?.(selector.domainUserInput);

        domainUserInput?.addEventListener(focusIn, () => handleDomainUserFocused({ domainValidationElement }));
        domainUserInput?.addEventListener(focusOut, () => handleDomainUserFocusLost({ scope, domainUserInput, domainValidationElement, EvRegexUtility }));
        domainUserInput?.addEventListener(keyup, () => handleDomainUserInput({ domainValidationElement, domainUserInput, EvRegexUtility }));

        if (domainUserInput && domainValidationElement) {
            handleDomainUserFocusLost({ scope, domainUserInput, domainValidationElement, EvRegexUtility });
        }
    }

    function handleDomainUserFocused({ domainValidationElement: element }) {
        const { dataset } = element;

        dataset.domainUserFocused = true;
    }

    function handleDomainUserFocusLost({ scope, domainValidationElement: element, domainUserInput, EvRegexUtility }) {
        const { hasError = '' } = className;
        const { dataset } = element;
        const { value = '' } = domainUserInput || {};
        const formGroup = domainUserInput?.closest?.(selector.formGroup);

        if (formGroup && value) {
            formGroup.classList?.remove?.(hasError);

            dataset.emptyUser = false;
        } else if (formGroup && !value) {
            formGroup.classList?.add?.(hasError);

            dataset.emptyUser = true;
        }

        dataset.domainUserFocused = false;

        updatePersonalisation({ element, value, EvRegexUtility });

        scope?.onUserValueChanged?.({ value });

        scope?.$applyAsync?.();
    }

    function handleDomainUserInput({ domainValidationElement: element, domainUserInput, EvRegexUtility }) {
        updatePersonalisation({ element, domainUserInput, EvRegexUtility });
    }

    function updatePersonalisation({ element, value = '', domainUserInput, EvRegexUtility }) {
        const { dataset = {} } = element || {};
        const user = value || domainUserInput?.value || '';
        const personalisation = EvRegexUtility.containsPersonalisation(user);

        dataset.personalisation = personalisation;
    }
}