{
    const { app } = window;
    const config = {
        url: {
            getQuotaInfo: '/billing/quota-info'
        }
    };
    const dependencies = [
        '$log',
        '$http',
        '$httpParamSerializer',
        '$q',
        '$window',
        BillingService
    ];

    app.service('EvBillingService', dependencies);

    function BillingService($log, $http, $q) {
        const methods = {
            getQuotaInfo
        };

        return methods;

        function getQuotaInfo() {
            const response = $http.get(config.url.getQuotaInfo)
                .then(handleGetInfoSuccess)
                .catch(handleError);

            return response;
        }

        function handleGetInfoSuccess({ data = {} }) {
            const { error, quotaInfo = {} } = data;

            if (error) {
                return handleError(error);
            }

            return { quotaInfo };
        }

        function handleError(error) {
            const deferred = $q.defer();

            deferred.reject(error);

            return deferred.promise;
        }
    }
}
