import template from '../templates/ev-table.component.html?raw';

app.component('evTable', {
    controllerAs: '$evTable',
    bindings: {
        classes: '@',
        headers: '<',
        data: '<',
        onClickTableItem: '&'
    },
    template
});