import template from '../templates/ev-composition-properties-sms-details.component.html?raw';

{
    const { app } = window;

    app.component('evCompositionPropertiesSmsDetails', {
        controllerAs: '$evCompositionPropertiesSmsDetailsCtrl',
        transclude: {
            heading: '?evCompositionPropertiesSmsDetailsHeading',
            nameLabel: '?evCompositionPropertiesSmsDetailsNameLabel',
            descriptionLabel: '?evCompositionPropertiesSmsDetailsDescriptionLabel',
            campaign: '?evCompositionPropertiesSmsDetailsCampaign'
        },
        bindings: {
            messageId: '<',
            type: '@',
            composer: '@',
            name: '<',
            description: '<',
            onNameChanged: '&',
            onDescriptionChanged: '&'
        },
        template
    });
}