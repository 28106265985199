{
    const { app, location } = window;
    const { pathname } = location;
    const dependencies = [
        '$scope',
        '$log',
        'EvRegexUtility',
        'EvInputDomainValidationService',
        EvInputDomainValidationController
    ];
    const config = {
        emailSymbol: '@',
        urlPath: {
            campaignAbProperties: '/campaigns/ab/properties',
            emailCompositionProperties: '/email-composition/properties',
            emailCompositionEmailProperties: '/email-composition/email-properties',
            workflowCompositionProperties: '/workflows/composition/properties'
        }
    };
    const errorMessage = {
        failedToGetValidDomains: 'Failed to get valid domains'
    };

    app.controller('EvInputDomainValidationController', dependencies);

    function EvInputDomainValidationController($scope, $log, EvRegexUtility, EvInputDomainValidationService) {
        const { validatedDomains = [], fromEmail = '' } = window;
        const personalisation = fromEmail && EvRegexUtility.containsPersonalisation(fromEmail);
        const { user = fromEmail, domain } = !personalisation && fromEmail ? EvRegexUtility.getEmailParts(fromEmail) : {};
        const urlPath = getUrlPath();
        const { $evInputDomainValidationCtrl: $ctrl } = $scope;
        const validatedDomainOptions = validatedDomains.map(({ domain_id: value, domain_domain: label }) => ({ label, value, checked: label === domain }));

        $ctrl.config = config;
        $ctrl.urlPath = urlPath;
        $ctrl.validatedDomains = validatedDomains.length && validatedDomains;
        $ctrl.validatedDomainOptions = validatedDomainOptions;
        $ctrl.user = user;
        $ctrl.domain = undefined;
        $ctrl.fromEmail = undefined;
        $ctrl.personalisation = personalisation;

        $ctrl.handleInputDomainValidationUserValueChanged = value => handleInputDomainValidationUserValueChanged({ $scope, $log, EvRegexUtility, value });
        $ctrl.handleInputDomainValidationDomainValueChanged = option => handleInputDomainValidationDomainValueChanged({ $scope, $log, option });

        if (!$ctrl.validatedDomains) {
            EvInputDomainValidationService
                .getValidDomains()
                .then(response => handleGetValidDomainsSuccess({ $log, $scope, ...response }))
                .catch(response => handleGetValidDomainsFailure({ $log, ...response }));
        }
    }

    function getUrlPath() {
        let urlPath = pathname;

        if (urlPath.startsWith(config.urlPath.campaignAbProperties)) {
            urlPath = config.urlPath.campaignAbProperties;
        } else if (
            urlPath.startsWith(config.urlPath.emailCompositionProperties)
            || urlPath.startsWith(config.urlPath.emailCompositionEmailProperties)
        ) {
            urlPath = config.urlPath.emailCompositionProperties;
        } else if (urlPath.startsWith(config.urlPath.workflowCompositionProperties)) {
            urlPath = config.urlPath.workflowCompositionProperties;
        }

        return urlPath;
    }

    function handleInputDomainValidationUserValueChanged({ $scope, $log, EvRegexUtility, value: user = '' }) {
        const { $evInputDomainValidationCtrl: $ctrl } = $scope;
        const personalisation = EvRegexUtility.containsPersonalisation(user);

        $ctrl.user = user;

        $ctrl.personalisation = personalisation;

        updateFromEmail({ $scope });
    }

    function handleInputDomainValidationDomainValueChanged({ $scope, $log, option = {} }) {
        const { $evInputDomainValidationCtrl: $ctrl = {} } = $scope;
        const { validatedDomains = [], validatedDomainOptions = [] } = $ctrl;
        const { value: domainId } = option;
        const domain = validatedDomains.find(({ domain_id }) => domain_id === domainId);

        $ctrl.domain = domain;

        for (const validatedDomainOption of validatedDomainOptions) {
            validatedDomainOption.checked = validatedDomainOption.value === domainId;
        }

        updateFromEmail({ $scope });
    }

    function updateFromEmail({ $scope }) {
        const { emailSymbol } = config;
        const { $evInputDomainValidationCtrl: $ctrl = {} } = $scope;
        const { user = '', domain = {}, personalisation } = $ctrl;
        const domainName = domain?.domain_domain;

        let fromEmail = user && domainName && [user, domainName].join(emailSymbol);

        if (user && personalisation) {
            fromEmail = user;
        }

        $ctrl.fromEmail = fromEmail;
    }

    function handleGetValidDomainsSuccess({ $log, $scope, validDomains = [], validDomainOptions = [] }) {
        const { $evInputDomainValidationCtrl: $ctrl = {} } = $scope;

        $ctrl.validatedDomains = validDomains;
        $ctrl.validatedDomainOptions = validDomainOptions;

        $scope.$applyAsync();
    }

    function handleGetValidDomainsFailure({ $log, error }) {
        const { failedToGetValidDomains: message } = errorMessage;

        $log.error(message);
        $log.error(error);
    }
}