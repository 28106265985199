import template from '../templates/ev-stat-legend.component.html?raw';

{
    app.component('evStatLegend', {
        controllerAs: '$evStatLegendCtrl',
        bindings: {
            data: '<'
        },
        template
    });
}