{
    const { app } = window;
    const dependencies = [
        '$scope',
        '$log',
        'EvSmsCompositionComposeService',
        EvSmsCompositionComposeController
    ];
    const controllerName = 'EvSmsCompositionComposeController';
    const errorMessage = {
        failedToGetPersonalisationFields: `${controllerName}: Failed to get personalisation fields`,
        failedToGetPersonalisationFieldNames: `${controllerName}: Failed to get personalisation field names`,
        primaryFallbackFailedToGetPersonalisationFields: `${controllerName}: Primary fallback failed to get personalisation fields`,
        retryForPersonalisationFieldsFailed: `${controllerName}: Retry for personalisation fields failed`
    };

    app.controller(controllerName, dependencies);

    function EvSmsCompositionComposeController($scope, $log, EvSmsCompositionComposeService) {
        const { $evSmsCompositionComposeCtrl: $ctrl } = $scope;

        $ctrl.personalisationFields = undefined;
        $ctrl.personalisationFieldNames = [];
        $ctrl.personalisationErrorFields = [];
        $ctrl.personalisationRetryErrorFields = [];

        EvSmsCompositionComposeService
            .getPersonalisationFields()
            .then(response => handleGetPersonalisationFieldsSuccess({ $log, $scope, ...response }))
            .catch(response => handleGetPersonalisationFieldsFailure({ $log, $scope, ...response, EvSmsCompositionComposeService }));
    }

    function handleGetPersonalisationFieldsSuccess({ $scope, personalisationFields = {} }) {
        const { $evSmsCompositionComposeCtrl: $ctrl } = $scope;

        $ctrl.personalisationFields = personalisationFields;

        $scope.$applyAsync();
    }

    function handleGetPersonalisationFieldsFailure({ $log, $scope, error, EvSmsCompositionComposeService }) {
        const { failedToGetPersonalisationFields: message } = errorMessage;

        $log.error(message);

        EvSmsCompositionComposeService
            .getPersonalisationFieldNames()
            .then(response => handleGetPersonalisationFieldNamesSuccess({ $log, $scope, EvSmsCompositionComposeService, ...response }))
            .catch(response => handleGetPersonalisationFieldNamesFailure({ $log, ...response }));
    }

    function handleGetPersonalisationFieldNamesSuccess({ $log, $scope, EvSmsCompositionComposeService, personalisationFieldNames = [] }) {
        const { $evSmsCompositionComposeCtrl: $ctrl } = $scope;

        $ctrl.personalisationFieldNames = personalisationFieldNames;

        EvSmsCompositionComposeService
            .getPersonalisationFieldsFallback({ fields: personalisationFieldNames })
            .then(response => handleGetPersonalisationFieldsFallbackSuccess({ $log, $scope, EvSmsCompositionComposeService, ...response }))
            .catch(response => handleGetPersonalisationFieldsFallbackFailure({ $log, ...response }));

        $scope.$applyAsync();
    }

    function handleGetPersonalisationFieldNamesFailure({ $log, error }) {
        const { failedToGetPersonalisationFieldNames: message } = errorMessage;

        $log.error(message);
    }

    function handleGetPersonalisationFieldsFallbackSuccess({ $log, $scope, EvSmsCompositionComposeService, personalisationFields = {}, personalisationErrorFields = {} }) {
        const { $evSmsCompositionComposeCtrl: $ctrl } = $scope;

        $ctrl.personalisationFields = personalisationFields;
        $ctrl.personalisationErrorFields = personalisationErrorFields;

        if (personalisationErrorFields.length) {
            EvSmsCompositionComposeService
                .getPersonalisationFieldsRetry({ fields: personalisationErrorFields })
                .then(response => handleGetPersonalisationFieldsRetrySuccess({ $log, $scope, EvSmsCompositionComposeService, ...response }))
                .catch(response => handleGetPersonalisationFieldsRetryFailure({ $log, ...response }));
        }

        $scope.$applyAsync();
    }

    function handleGetPersonalisationFieldsFallbackFailure({ $log, error }) {
        const { primaryFallbackFailedToGetPersonalisationFields: message } = errorMessage;

        $log.error(message);
    }

    function handleGetPersonalisationFieldsRetrySuccess({ $scope, personalisationFields: personalisationFieldsRetry = {}, personalisationErrorFields: personalisationRetryErrorFields = {} }) {
        const { $evSmsCompositionComposeCtrl: $ctrl } = $scope;
        const { personalisationFields = {} } = $ctrl;

        $ctrl.personalisationFields = {
            ...personalisationFields,
            ...personalisationFieldsRetry
        };

        $ctrl.personalisationRetryErrorFields = personalisationRetryErrorFields;

        $scope.$applyAsync();
    }

    function handleGetPersonalisationFieldsRetryFailure({ $log, error }) {
        const { retryForPersonalisationFieldsFailed: message } = errorMessage;

        $log.error(message);
    }
}