{
    const { app, location, requestIdleCallback = setTimeout, SMS, Properties } = window;
    const { pathname = '' } = location;
    const dependencies = [
        '$scope',
        '$log',
        'EvRegexUtility',
        'EvSmsCompositionPropertiesService',
        'EvScriptUtility',
        EvSmsCompositionPropertiesController
    ];
    const controllerName = 'EvSmsCompositionPropertiesController';
    const config = {
        defaultTimeout: 5000,
        url: {
            root: '/sms-composition',
            path: {
                properties: 'properties'
            },
            scripts: [
                '/js/lib/Sms/properties.js',
                '/js/lib/Sms/toggle-radio-buttons.js'
            ]
        },
        template: {
            header: '/sms-composition/properties/header',
            messageInput: '/sms-composition/properties/message-details',
            messageCampaign: '/sms-composition/properties/message-campaign',
            createCampaignModal: '/sms-composition/properties/create-campaign-modal'
        },
        readyFields: [
            'campaignModalReady',
            'campaignsReady'
        ]
    };
    const errorMessage = {
        failedToGetMessageData: 'Failed to get message',
        failedToGetStandardCampaigns: 'Failed to get standard campaings'
    };

    app.controller(controllerName, dependencies);

    function EvSmsCompositionPropertiesController($scope, $log, EvRegexUtility, EvSmsCompositionPropertiesService, EvScriptUtility) {
        const { $evSmsCompositionPropertiesCtrl: $ctrl } = $scope;
        const { template = {} } = config;
        const messageId = EvRegexUtility.getMessageId(pathname) || undefined;

        $ctrl.template = template;
        $ctrl.messageId = messageId;
        $ctrl.message = {
            message_id: messageId,
            message_campaign_id: undefined,
            message_type: 'sms',
            message_compose_with: 'classic',
            message_subject: '',
            message_campaign_name: ''
        };
        $ctrl.includeCampaign = false;
        $ctrl.properties = undefined;
        $ctrl.campaigns = undefined;
        $ctrl.campaignModalReady = false;
        $ctrl.campaignsReady = false;
        $ctrl.ready = false;

        $ctrl.handleNameChanged = name => handleNameChanged({ $scope, name });
        $ctrl.handleDescriptionChanged = description => handleDescriptionChanged({ $scope, description });
        $ctrl.handleCampaignClicked = campaign => handleCampaignClicked({ $scope, campaign });
        $ctrl.handleCampaignClear = () => handleCampaignClear({ $scope });
        $ctrl.handleCampaignsReady = () => handleCampaignsReady({ $scope, EvScriptUtility });
        $ctrl.handleCampainModalReady = () => handleCampainModalReady({ $scope, EvScriptUtility });

        if (messageId) {
            setMessage({ $scope, $log, EvSmsCompositionPropertiesService });
        } else {
            setCampaigns({ $scope, $log, EvSmsCompositionPropertiesService });
        }
    }

    function setMessage({ $scope, $log, EvSmsCompositionPropertiesService }) {
        const { $evSmsCompositionPropertiesCtrl: $ctrl } = $scope;
        const { message = {} } = $ctrl;
        const { message_id: messageId } = message;

        EvSmsCompositionPropertiesService
            .getMessage({ messageId })
            .then(({ message = {} }) => handleGetMessageSuccess({ $scope, $log, EvSmsCompositionPropertiesService, message }))
            .catch(error => handleGetMessageFailure({ $log, error }));
    }

    function setCampaigns({ $scope, $log, EvSmsCompositionPropertiesService }) {
        EvSmsCompositionPropertiesService
            .getStandardCampaigns()
            .then(({ campaigns = [] }) => handleGetStandardCampaignsSuccess({ $scope, $log, campaigns }))
            .catch(error => handleGetStandardCampaignsFailure({ $log, error }));
    }

    function handleGetMessageSuccess({ $scope, $log, EvSmsCompositionPropertiesService, message = {} }) {
        const { $evSmsCompositionPropertiesCtrl: $ctrl } = $scope;

        $ctrl.message = message;

        setCampaigns({ $scope, $log, EvSmsCompositionPropertiesService });
    }

    function handleGetMessageFailure({ $log, error }) {
        const { failedToGetMessageData: message } = errorMessage;

        $log.error(message);
        $log.error(error);
    }

    function handleGetStandardCampaignsSuccess({ $scope, $log, campaigns = [] }) {
        const { $evSmsCompositionPropertiesCtrl: $ctrl } = $scope;
        const { message = {} } = $ctrl;
        const { message_campaign_id: campaignId } = message;

        for (const campaign of campaigns) {
            campaign.selected = campaignId === campaign.campaign_id;
        }

        $ctrl.campaigns = campaigns;

        requestIdleCallback(() => {});
    }

    function handleGetStandardCampaignsFailure({ $log, error }) {
        const { failedToGetStandardCampaigns: message } = errorMessage;

        $log.error(message);
        $log.error(error);
    }

    function handleNameChanged({ $scope, name }) {
        const { $evSmsCompositionPropertiesCtrl: $ctrl } = $scope;
        const { message = {} } = $ctrl;

        message.message_subject = name;
    }

    function handleDescriptionChanged({ $scope, description }) {
        const { $evSmsCompositionPropertiesCtrl: $ctrl } = $scope;
        const { message = {} } = $ctrl;

        message.message_campaign_name = description;
    }

    function handleCampaignClicked({ $scope, campaign: selectedCampaign = {} }) {
        const { $evSmsCompositionPropertiesCtrl: $ctrl } = $scope;
        const { campaigns = [] } = $ctrl;
        const currentCampaigns = [];

        for (const campaign of campaigns) {
            currentCampaigns.push({
                ...campaign,
                selected: selectedCampaign.campaign_id === campaign.campaign_id
            });
        }

        $ctrl.campaigns = currentCampaigns;
    }

    function handleCampaignClear({ $scope }) {
        const { $evSmsCompositionPropertiesCtrl: $ctrl } = $scope;
        const { campaigns = [] } = $ctrl;
        const currentCampaigns = campaigns.map(campaign => ({ ...campaign, selected: false }));

        $ctrl.campaigns = currentCampaigns;
    }

    function handleCampainModalReady({ $scope, EvScriptUtility }) {
        const { $evSmsCompositionPropertiesCtrl: $ctrl } = $scope;

        $ctrl.campaignModalReady = true;

        handleReady({ $scope, EvScriptUtility });
    }

    function handleCampaignsReady({ $scope, EvScriptUtility }) {
        const { $evSmsCompositionPropertiesCtrl: $ctrl } = $scope;

        $ctrl.campaignsReady = true;

        handleReady({ $scope, EvScriptUtility })
    }

    function handleReady({ $scope, EvScriptUtility }) {
        const { readyFields = [], defaultTimeout } = config;
        const { $evSmsCompositionPropertiesCtrl: $ctrl } = $scope;
        const ready = readyFields.every(field => Boolean($ctrl[field]));

        $ctrl.ready = ready;

        if (ready) {
            setTimeout(() => requestIdleCallback(() => loadScripts({ $scope, EvScriptUtility })), defaultTimeout);
        }
    }

    function loadScripts({ $scope, EvScriptUtility }) {
        const { scripts = [] } = config.url;

        EvScriptUtility.loadScripts({ scripts, timestamp: true });
    }
}