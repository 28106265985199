import template from '../templates/ev-tag-list.component.html?raw';

{
    app.component('evTagList', {
        controllerAs: '$evTagListCtrl',
        bindings: {
            allowIconClick: '@?',
            tags: '<',
            search: '<',
            onIconClick: '&'
        },
        template
    });
}