{
    const { requestIdleCallback = setTimeout } = window;
    const config = {
        restrict: 'A',
        controllerAs: '$evTeleport',
        link: (scope, { 0: element }, attributes) => requestIdleCallback(() => link({ scope, element, attributes }))
    };
    const directiveName = 'evTeleport';

    app.directive(directiveName, () => config);

    function link({ element, attributes }) {
        const { body } = document;
        const { parentElement: originalParent } = element;
        const { directiveName: selector = '' } = attributes;
        const parent = selector && body.querySelector(selector) || body;

        if (parent !== originalParent) {
            originalParent?.removeChild(element);
            parent?.appendChild(element);
        }
    }
}