import template from '../templates/ev-input-text.component.html?raw';

{
    app.component('evInputText', {
        controllerAs: '$evInputTextCtrl',
        transclude: {
            label: '?evInputLabel'
        },
        bindings: {
            inputId: '@?',
            value: '@?',
            icon: '@?',
            iconText: '@?',
            readOnly: '@?',
            placeholderText: '@?',
            onValueChanged: '&'
        },
        template
    });
}