{
    const dependencies = [
        '$log',
        EvRegexUtility
    ];
    const regex = {
        personalisation: /\{{2}(.+)\}{2}/,
        emailAt: /@/,
        trailingDigits: /\/(\d+)$/,
    };

    app.factory('EvRegexUtility', dependencies);

    function EvRegexUtility() {
        const methods = {
            containsPersonalisation,
            getEmailParts,
            getMessageId
        }

        return methods;
    }

    function containsPersonalisation(input = '') {
         const match = input.match(regex.personalisation);

         return Boolean(match);
    }

    function getEmailParts(email) {
        const [ user, domain ] = email.split(regex.emailAt);

        return { user, domain };
    }

    function getMessageId(url = '') {
        const messageId = url.match(regex.trailingDigits)?.[1] || '';

        return messageId;
    }
}