import baseChartOptions from "./base-chart-options.config";

const lineChartOptions = {
    ...baseChartOptions,
    title: {
        align: 'left',
        margin: 30
    },
    legend: {
        itemWidth: 80,
        align: 'left',
        x: 100,
        y: -55,
        verticalAlign: 'top',
    },
    xAxis: {
        title: {
            text: ''
        },
        labels: {
            y: 25
        }
    },
    yAxis: {
        title: {
            text: ''
        }
    }
};

export default lineChartOptions;