{
    const { app } = window;
    const dependencies = [
        '$scope',
        '$log',
        'EvLandingPageCompositionComposeService',
        EvLandingPageCompositionComposeController
    ];
    const controllerName = 'EvLandingPageCompositionComposeController';
    const errorMessage = {
        failedToGetPersonalisationFields: `${controllerName}: Failed to get personalisation fields`,
        failedToGetPersonalisationFieldNames: `${controllerName}: Failed to get personalisation field names`,
        primaryFallbackFailedToGetPersonalisationFields: `${controllerName}: Primary fallback failed to get personalisation fields`,
        retryForPersonalisationFieldsFailed: `${controllerName}: Retry for personalisation fields failed`
    };

    app.controller(controllerName, dependencies);

    function EvLandingPageCompositionComposeController($scope, $log, EvLandingPageCompositionComposeService) {
        const { $evLandingPageCompositionComposeCtrl: $ctrl } = $scope;

        $ctrl.personalisationFields = undefined;
        $ctrl.personalisationFieldNames = [];
        $ctrl.personalisationErrorFields = [];
        $ctrl.personalisationRetryErrorFields = [];

        EvLandingPageCompositionComposeService
            .getPersonalisationFields()
            .then(response => handleGetPersonalisationFieldsSuccess({ $log, $scope, ...response }))
            .catch(response => handleGetPersonalisationFieldsFailure({ $log, $scope, ...response, EvLandingPageCompositionComposeService }));
    }

    function handleGetPersonalisationFieldsSuccess({ $scope, personalisationFields = {} }) {
        const { $evLandingPageCompositionComposeCtrl: $ctrl } = $scope;

        $ctrl.personalisationFields = personalisationFields;

        $scope.$applyAsync();
    }

    function handleGetPersonalisationFieldsFailure({ $log, $scope, error, EvLandingPageCompositionComposeService }) {
        const { failedToGetPersonalisationFields: message } = errorMessage;

        $log.error(message);

        EvLandingPageCompositionComposeService
            .getPersonalisationFieldNames()
            .then(response => handleGetPersonalisationFieldNamesSuccess({ $log, $scope, EvLandingPageCompositionComposeService, ...response }))
            .catch(response => handleGetPersonalisationFieldNamesFailure({ $log, ...response }));
    }

    function handleGetPersonalisationFieldNamesSuccess({ $log, $scope, EvLandingPageCompositionComposeService, personalisationFieldNames = [] }) {
        const { $evLandingPageCompositionComposeCtrl: $ctrl } = $scope;

        $ctrl.personalisationFieldNames = personalisationFieldNames;

        EvLandingPageCompositionComposeService
            .getPersonalisationFieldsFallback({ fields: personalisationFieldNames })
            .then(response => handleGetPersonalisationFieldsFallbackSuccess({ $log, $scope, EvLandingPageCompositionComposeService, ...response }))
            .catch(response => handleGetPersonalisationFieldsFallbackFailure({ $log, ...response }));

        $scope.$applyAsync();
    }

    function handleGetPersonalisationFieldNamesFailure({ $log, error }) {
        const { failedToGetPersonalisationFieldNames: message } = errorMessage;

        $log.error(message);
    }

    function handleGetPersonalisationFieldsFallbackSuccess({ $log, $scope, EvLandingPageCompositionComposeService, personalisationFields = {}, personalisationErrorFields = {} }) {
        const { $evLandingPageCompositionComposeCtrl: $ctrl } = $scope;

        $ctrl.personalisationFields = personalisationFields;
        $ctrl.personalisationErrorFields = personalisationErrorFields;

        if (personalisationErrorFields.length) {
            EvLandingPageCompositionComposeService
                .getPersonalisationFieldsRetry({ fields: personalisationErrorFields })
                .then(response => handleGetPersonalisationFieldsRetrySuccess({ $log, $scope, EvLandingPageCompositionComposeService, ...response }))
                .catch(response => handleGetPersonalisationFieldsRetryFailure({ $log, ...response }));
        }

        $scope.$applyAsync();
    }

    function handleGetPersonalisationFieldsFallbackFailure({ $log, error }) {
        const { primaryFallbackFailedToGetPersonalisationFields: message } = errorMessage;

        $log.error(message);
    }

    function handleGetPersonalisationFieldsRetrySuccess({ $scope, personalisationFields: personalisationFieldsRetry = {}, personalisationErrorFields: personalisationRetryErrorFields = {} }) {
        const { $evLandingPageCompositionComposeCtrl: $ctrl } = $scope;
        const { personalisationFields = {} } = $ctrl;

        $ctrl.personalisationFields = {
            ...personalisationFields,
            ...personalisationFieldsRetry
        };

        $ctrl.personalisationRetryErrorFields = personalisationRetryErrorFields;

        $scope.$applyAsync();
    }

    function handleGetPersonalisationFieldsRetryFailure({ $log, error }) {
        const { retryForPersonalisationFieldsFailed: message } = errorMessage;

        $log.error(message);
    }
}