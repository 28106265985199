import template from '../templates/ev-info-graphic-funnel.component.html?raw';

{
    app.component('evInfoGraphicFunnel', {
        controllerAs: '$evInfoGraphicFunnelCtrl',
        transclude: {
            segment: '?funnelSegment'
        },
        template
    });
}