import template from '../templates/ev-dropdown-option.component.html?raw';

app.component('dropdownOption', {
    controllerAs: '$dropdownOption',
    bindings: {
        options: '<evOptions',
        hasParams: '<evParams',
        textColor: '@',
        onCancelClick: '&'
    },
    template: template
});
