{
    const { app } = window;
    const dependencies = [
        '$log',
        'EvMessageService',
        'EvCampaignService',
        EvSmsCompositionPropertiesService
    ];
    const config = {
        url: {
            getMessage: '/sms-composition/properties/message',
            getStandardCampaigns: '/sms-composition/properties/standard-campaigns'
        }
    };

    app.service('EvSmsCompositionPropertiesService', dependencies);

    function EvSmsCompositionPropertiesService($log, EvMessageService, EvCampaignService) {
        const methods = {
            getMessage: ({ messageId = '' }) => getMessage({ $log, EvMessageService, messageId }),
            getStandardCampaigns: () => getStandardCampaigns({ $log, EvCampaignService })
        };

        return methods;
    }

    function getMessage({ $log, EvMessageService, messageId = '' }) {
        const { getMessage: currentUrl } = config.url;
        const url = messageId ? `${currentUrl}/${messageId}` : currentUrl;
        const response = EvMessageService.getMessage({ url });

        return response;
    }

    function getStandardCampaigns({ $log, EvCampaignService }) {
        const { getStandardCampaigns: url } = config.url;
        const response = EvCampaignService.getStandardCampaigns({ url });

        return response;
    }
}