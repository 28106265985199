import baseChartOptions from "./base-chart-options.config";

const pieChartOptions = {
    ...baseChartOptions,
    chart: {
        ...baseChartOptions.chart,
        type: 'pie'
    },
    series: [{
        dataLabels: {
            enabled: false
        }
    }]
};

export default pieChartOptions;