import template from '../templates/ev-default-modal.component.html?raw';

app.component('evDefaultModal', {
    controllerAs: '$evDefaultModal',
    bindings: {
        headerClass: '@',
        modalClass: '@',
        modalId: '@',
    },
    transclude: {
        title: '?evTitle',
        body: '?evBody'
    },
    template
});