import template from '../templates/ev-info-graphic-pie-doghnut.component.html?raw';

{
    app.component('evInfoGraphicPieDoghnut', {
        controllerAs: '$evInfoGraphicPieDoghnutCtrl',
        bindings: {
            innerSize: '@',
            referenceAngle: '@',
            icon: '@',
            data: '<'
        },
        template
    });
}