{
    const dependencies = [
        '$log',
        EvScriptUtility
    ];

    app.factory('EvScriptUtility', dependencies);

    function EvScriptUtility($log) {
        const methods = {
            loadScripts: ({ scripts = [], timestamp = false }) => loadScripts({ $log, scripts, timestamp })
        }

        return methods;
    }

    function loadScripts({ $log, scripts = [], timestamp = false }) {
        const loadedScripts = Promise
            .all(scripts.map(url => loadScript({ url, timestamp })))
            .catch(error => handleError({ $log, error }));

        return loadedScripts;
    }

    function loadScript({ url = '', timestamp = false }) {
        const { head } = document;
        const script = document.createElement('script');
        const currentTime = (new Date()).getTime();
        const src = currentTime ? `${url}?d=${currentTime}` : url;

        script.src = src;

        return new Promise((resolve, reject) => {
            script.addEventListener('load', resolve);
            script.addEventListener('error', reject);

            head.appendChild(script);
        });
    }

    function handleError({ $log, error }) {
        $log.error(error);
    }
}