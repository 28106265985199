{
    const { requestIdleCallback = setTimeout } = window;
    const config = {
        restrict: 'A',
        controllerAs: '$evTooltip',
        link: (scope, { 0: element }, attributes) => requestIdleCallback(() => link({ scope, element, attributes }))
    };
    const tooltipPlacement = {
        bottom: 'bottom'
    };
    const directiveName = 'evTooltip';

    app.directive(directiveName, () => config);

    function link({ element, attributes }) {
        const { bottom } = tooltipPlacement;
        const { directiveName: placement = bottom } = attributes;
        const { dataset } = element;

        dataset.placement = placement;

        angular?.element(element)?.tooltip();
    }
}