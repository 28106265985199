{
    const { app, fetch } = window;
    const dependencies = [
        '$log',
        '$q',
        EvMessageService
    ];

    app.service('EvMessageService', dependencies);

    function EvMessageService($log, $q) {
        const methods = {
            getMessage: ({ url = '' }) => getMessage({ $log, $q, url })
        };

        return methods;
    }

    function getMessage({ $log, $q, url = '' }) {
        const deferred = $q.defer();
        const response = fetch(url)
            .then(response => response.json())
            .then(data => handleGetMessageSuccess({ $log, deferred, data }));

        return response;
    }

    function handleGetMessageSuccess({ deferred, data = {} }) {
        const { message = {}, error } = data;

        if (error) {
            return handleGetMessageFailure({ deferred, error });
        }

        deferred.resolve({ message });

        return deferred.promise

    }

    function handleGetMessageFailure({ deferred, error }) {
        deferred.reject(error);

        return deferred.promise;
    }
}