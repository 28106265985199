{
    const { app } = window;
    const dependencies = [
        '$log',
        CkFinderService
    ];
    const config = {
        modal: {
            width: 800,
            height: 600,
            chooseFiles: true,
            chooseFilesOnDblClick: true,
            chooseFilesClosePopup: true,
        },
        eventName: {
            filesChoose: 'files:choose',
            filesChooseResize: 'file:choose:resizedImage'
        }
    };

    app?.factory?.('ckFinder', dependencies);

    function CkFinderService($log)
    {
        const methods = {
            open: openModal
        }

        return methods;
    }

    function openModal(controller, index = null)
    {
        const { handleImageSelection: callback = () => {} } = controller;
        const { CKFinder } = window;
        const modalConfig = {
            ...config.modal,
            onInit: finder => onInit({ finder, callback, index })
        };

        CKFinder?.modal(modalConfig);
    }

    function onInit({ finder, callback, index }) {
        const { eventName } = config;
        const { filesChoose, filesChooseResize } = eventName;

        finder?.on?.(filesChoose, event => handleFilesChosen({ event, callback, index }));
        finder?.on?.(filesChooseResize, event => handleFileChosenResize({ event, callback }));
    }

    function handleFilesChosen({ event, callback, index }) {
        const fileUrl = getUncachedUrl({
            url: getFirstUrl({ event })
        });

        callback?.(fileUrl, index);
    }

    function handleFileChosenResize({ event, callback, index }) {
        const fileUrl = getUncachedUrl({
            url: getResizeUrl({ event })
        });

        callback?.(fileUrl, index);
    }

    function getFirstUrl({ event }) {
        const firstFileUrl = event?.data?.files?.first?.()?.attributes?.url;

        return firstFileUrl;
    }

    function getResizeUrl({ event }) {
        const resizeUrl = event?.data?.resizedUrl;

        return resizeUrl;
    }

    function getUncachedUrl({ url }) {
        const uncachedUrl = [
            url,
            '?v=',
            new Date().getTime()
        ].join('');

        return uncachedUrl;
    }
}