{
    const { app } = window;
    const dependencies = [
        '$q',
        '$log',
        'EvPersonalisationService',
        EvSmsCompositionComposeService
    ];
    const config = {
        url: {
            getPersonalisationFields: '/sms-composition/compose/personalisation-fields',
            getPersonalisationFieldNames: '/sms-composition/compose/personalisation-field-names',
            getPersonalisationFieldsFallback: '/sms-composition/compose/personalisation-field'
        }
    }

    app.service('EvSmsCompositionComposeService', dependencies);

    function EvSmsCompositionComposeService($q, $log, EvPersonalisationService) {
        const methods = {
            getPersonalisationFields: () => getPersonalisationFields({ $q, $log, EvPersonalisationService }),
            getPersonalisationFieldNames: () => getPersonalisationFieldNames({ $q, $log, EvPersonalisationService }),
            getPersonalisationFieldsFallback: ({ fields = [] }) => getPersonalisationFieldsFallback({ $q, $log, EvPersonalisationService, fields }),
            getPersonalisationFieldsRetry: ({ fields = [] }) => getPersonalisationFieldsRetry({ $q, $log, EvPersonalisationService, fields })
        };

        return methods;
    }

    function getPersonalisationFields({ EvPersonalisationService }) {
        const { getPersonalisationFields: url } = config.url;
        const response = EvPersonalisationService
            .getPersonalisationFields({ url });

        return response;
    }

    function getPersonalisationFieldNames({ EvPersonalisationService }) {
        const { getPersonalisationFieldNames: url } = config.url;
        const response = EvPersonalisationService
            .getPersonalisationFieldNames({ url });

        return response;
    }

    function getPersonalisationFieldsFallback({ EvPersonalisationService, fields = [] }) {
        const { getPersonalisationFieldsFallback: url } = config.url;
        const response = EvPersonalisationService
            .getPersonalisationFieldsFallback({ url, fields });

        return response;
    }

    function getPersonalisationFieldsRetry({ EvPersonalisationService, fields }) {
        const { getPersonalisationFieldsFallback: url } = config.url;
        const response = EvPersonalisationService
            .getPersonalisationFieldsRetry({ url, fields });

        return response;
    }
}