import template from '../templates/ev-info-graphic-horizontal-stacked-bar.component.html?raw';

{
    app.component('evInfoGraphicHorizontalStackedBar', {
        controllerAs: '$evInfoGraphicHorizontalStackedBarCtrl',
        bindings: {
            legend: '@',
            totalLabel: '@',
            data: '<'
        },
        transclude: {
            title: '?infoGraphicTitle'
        },
        template
    });
}