import template from '../templates/ev-composition-properties-standard-campaign.component.html?raw';

const { app } = window;

    app.component('evCompositionPropertiesStandardCampaign', {
        controllerAs: '$evCompositionPropertiesStandardCampaignCtrl',
        transclude: {
            heading: '?evCompositionPropertiesStandardCampaignHeading',
            toggleLabel: '?evCompositionPropertiesStandardCampaignToggleLabel',
            nameLabel: '?evCompositionPropertiesStandardCampaignNameLabel',
            clearLabel: '?evCompositionPropertiesStandardCampaignClearLabel'
        },
        bindings: {
            includeCampaign: '<',
            campaignId: '<',
            campaigns: '<',
            onReady: '&'
        },
        template
    });