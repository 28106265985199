{
    const { app } = window;
    const dependencies = [
        '$log',
        '$scope',
        'alertService',
        'EvBillingService',
        BillingController
    ];
    const errorMessage = {
        failedToGetQuotaInfo: 'Failed to get quota info.'
    };

    app.controller('EvBillingController', dependencies);

    function BillingController($log, $scope, alertService, EvBillingService) {
        const { $billingController: $ctrl = {} } = $scope;

        $ctrl.quotaInfo = {};

        EvBillingService
            .getQuotaInfo()
            .then(handleGetInfoSuccess)
            .catch(handleGetInfoFailure);

        function handleGetInfoSuccess({ quotaInfo = {} }) {
            $ctrl.quotaInfo = quotaInfo;
        }

        function handleGetInfoFailure(error) {
            const { failedToGetQuotaInfo: alertMessage } = errorMessage;

            logError({ error, alertMessage });
        }

        function logError({ error, alertMessage }) {
            $log.error(error);
            $log.error(alertMessage);
            alertService.addError(alertMessage);
        }
    }
}
