import template from '../templates/ev-chart.component.html?raw';

app.component('evChart', {
    controllerAs: '$evChartCtrl',
    bindings: {
        type: '@',
        title: '@',
        innerSize: '@',
        referenceAngle: '@',
        data: '<',
        chartOptions: '<'
    },
    template
});